import { InputComponentProps } from "./InputComponent.types";
import React from "react";
import "./InputComponent.scss";

const InputComponent: React.FC<InputComponentProps> = ({
  placeholder,
  type = "text",
  disabled,
  required,
  value,
  onChange,
  label,
}) => {
  // marks placeholder with a * if required
  const localPlaceholder = `${placeholder ? placeholder : ""}${
    required ? `${placeholder ? " " : ""}*` : ""
  }`;
  const labelText = `${label ? label : ""}${
    required ? `${label ? " " : ""}*` : ""
  }`;

  return (
    <div data-testid="InputComponent" className="input-component">
      {label && (
        <div className="input-label">
          <p>{labelText}</p>
        </div>
      )}
      {type !== "multiline" ? (
        <input
          data-testid="inner-input"
          disabled={disabled}
          required={required}
          type={type}
          placeholder={localPlaceholder}
          value={value}
          onChange={(event) =>
            onChange ? onChange(event.target.value) : () => {}
          }
        />
      ) : (
        <textarea
          disabled={disabled}
          required={required}
          placeholder={localPlaceholder}
          value={value}
          onChange={(event) =>
            onChange ? onChange(event.target.value) : () => {}
          }
        />
      )}
    </div>
  );
};

export default InputComponent;
