// Generated with util/create-component.js
import React, { useRef, useState } from "react";
import ButtonComponent from "../ButtonComponent/ButtonComponent";
import "./CookieBannerComponent.scss";
import { CookieBannerComponentProps } from "./CookieBannerComponent.types";
import CheckboxComponent from "../CheckboxComponent/CheckboxComponent";
import PopupComponent from "../PopUpComponent/PopUpComponent";
import Collapsible from "react-collapsible";
import { ReactComponent as ChevronDown } from "./assets/circle-chevron-down-solid.svg";
import { ReactComponent as ChevronUp } from "./assets/circle-chevron-up-solid.svg";

const CookieBannerComponent: React.FC<CookieBannerComponentProps> = ({
  config,
}) => {
  const [showEditPopup, toggleEditPopup] = useState<boolean>(false);
  const [isAnswered, setIsAnswered] = useState<boolean>(false);
  const [openCollapsible, setOpenCollapsible] = useState<
    ("needed" | "marketing")[]
  >([]);

  /**
   * Helper to determine if currently a collapsible is open or not
   * @param type which is open "needed" | "marketing"
   */
  const toggleOpenCollapsible = (type: "needed" | "marketing"): void => {
    if (openCollapsible.includes(type))
      setOpenCollapsible(openCollapsible.filter((item) => item !== type));
    else setOpenCollapsible([...openCollapsible, type]);
  };

  return (
    <>
      {config.active && !isAnswered && (
        <div
          data-testid="CookieBannerComponent"
          className="cookie-banner-component"
        >
          <PopupComponent open={showEditPopup} toggleOpen={toggleEditPopup}>
            <div className="popup--content">
              <p className="popup--content--header">
                {config.header ?? "Einstellungen für die Zustimmung anpassen"}
              </p>
              <p className="popup--content--text">
                {config.text ??
                  "Wir verwenden Cookies, damit Sie effizient navigieren und bestimmte Funktionen ausführen können. Detaillierte Informationen zu allen Cookies finden Sie unten unter jeder Einwilligungskategorie. Die als 'notwendig' kategorisierten Cookies werden in Ihrem Browser gespeichert, da sie für die Aktivierung der grundlegenden Funktionalitäten der Website unerlässlich sind. Wir verwenden auch Cookies von Drittanbietern, die uns dabei helfen, zu analysieren, wie Siediese Website nutzen, Ihre Präferenzen zu speichern und die für Sie relevanten Inhalte und Werbeanzeigen bereitzustellen. Diese Cookies werden nur mit Ihrer vorherigen Einwilligung in Ihrem Browser gespeichert. Sie können einige oder alle dieser Cookies aktivieren oder deaktivieren, aber die Deaktivierung einiger dieser Cookies kann Ihre Browser-Erfahrung beeinträchtigen."}
              </p>
              <Collapsible
                onOpen={() => toggleOpenCollapsible("needed")}
                onClose={() => toggleOpenCollapsible("needed")}
                trigger={
                  <p className="popup--content--header--cookie-type">
                    {config.needed ?? "Notwendig"}
                    <div className="icon">
                      {openCollapsible.includes("needed") ? (
                        <ChevronUp />
                      ) : (
                        <ChevronDown />
                      )}
                    </div>
                  </p>
                }
              >
                <CheckboxComponent
                  type="cookie"
                  value={config.essentailCookiesText ?? "Notwendige Cookies"}
                  checked
                  disabled
                  onCheck={() => {}}
                />
              </Collapsible>

              <Collapsible
                onOpen={() => toggleOpenCollapsible("marketing")}
                onClose={() => toggleOpenCollapsible("marketing")}
                trigger={
                  <p className="popup--content--header--cookie-type">
                    {config.marketing ?? "Marketing"}
                    <div className="icon">
                      {openCollapsible.includes("marketing") ? (
                        <ChevronUp />
                      ) : (
                        <ChevronDown />
                      )}
                    </div>
                  </p>
                }
              >
                {config.checkItems ?? []}
              </Collapsible>
            </div>
            <div className="popup--buttons">
              <ButtonComponent
                value={config.acceptEssentialButtonText ?? "Nur Notwendige"}
                onClick={() => {
                  setIsAnswered(true);
                  config.onAcceptEssential();
                }}
                key={"essentialButton"}
                color="lightgray"
              />
              <ButtonComponent
                value={config.acceptSelectedButtonText ?? "Auswahl akzeptieren"}
                key={"acceptSelectedlButton"}
                onClick={() => {
                  setIsAnswered(true);
                  config.onAcceptSelected();
                }}
                color="lightgray"
              />
              <ButtonComponent
                value={config.acceptAllButtonText ?? "Alle akzeptieren"}
                key={"acceptAllButton"}
                onClick={() => {
                  setIsAnswered(true);
                  config.onAcceptAll();
                }}
              />
            </div>
          </PopupComponent>

          <div className="fixed--header">
            {config.smallHeader ?? "Wir schätzen deine Privatsphäre"}
          </div>
          <div className="fixed--text">
            {config.smallText ??
              "Wir verwenden Cookies, um dein Surferlebnis zu verbessern, personalisierte Anzeigen zu erstellen und unseren Datenverkehr zu analysieren. Wenn du auf 'Alle akzeptieren' klickst, stimmst du der Verwendung von Cookies zu."}
          </div>
          <div className="fixed--buttons">
            {(config.checkItems ?? []).length > 0 && (
              <>
                <ButtonComponent
                  value={config.customizeChoiceButtonText ?? "Anpassen"}
                  key={"customizeChoice"}
                  onClick={() => toggleEditPopup(true)}
                  color="lightgray"
                />
                <ButtonComponent
                  value={config.acceptEssentialButtonText ?? "Nur Notwendige"}
                  onClick={() => {
                    setIsAnswered(true);
                    config.onAcceptEssential();
                  }}
                  key={"essentialButton"}
                  color="lightgray"
                />
              </>
            )}
            <ButtonComponent
              value={config.acceptAllButtonText ?? "Alle akzeptieren"}
              key={"acceptAllButton"}
              onClick={() => {
                setIsAnswered(true);
                config.onAcceptAll();
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default CookieBannerComponent;
