// Generated with util/create-component.js
import React from "react";

import { LoaderComponentProps } from "./LoaderComponent.types";
import RanLogo from "../assets/ran_logo.png";
import "./LoaderComponent.scss";

const LoaderComponent: React.FC<LoaderComponentProps> = ({
  fullscreen = false,
  text,
}) => (
  <div
    data-testid="LoaderComponent"
    className={[
      "loader-component",
      fullscreen ? "loader-component-fullscreen" : undefined,
    ].join(" ")}
  >
    <div className="loader-component--image-wrapper">
      <img src={RanLogo} alt="Apprich Secur logo" />
      {!!text && <p className="loader-component--image-wrapper-text">{text}</p>}
    </div>
  </div>
);

export default LoaderComponent;
