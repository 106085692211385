// Generated with util/create-component.js
import React from "react";

import { PopupComponentProps } from "./PopUpComponent.types";
import { ReactComponent as ClearIcon } from "../assets/close.svg";

import "./PopUpComponent.scss";
import ButtonComponent from "../ButtonComponent/ButtonComponent";

const PopupComponent: React.FC<PopupComponentProps> = ({
  open,
  toggleOpen,
  title = "",
  bottomButtons,
  children,
}) => {
  return (
    <>
      {open && (
        <div
          data-testid="PopupComponent"
          className="popup-component--wrapper"
          onClick={(evt) => {
            evt.stopPropagation();
            evt.preventDefault();
            toggleOpen(false);
          }}
        >
          <div
            className="popup-component--wrapper-content-wrapper"
            onClick={(evt) => evt.stopPropagation()}
          >
            <div className="title">
              <div data-testid="inner-popup" className="title-headline">
                {title}
              </div>
              <div className="title-close" onClick={() => toggleOpen(false)}>
                <ClearIcon />
              </div>
            </div>

            <div className="content">{children}</div>

            {!!bottomButtons && bottomButtons.length > 0 && (
              <div className="footer">
                {bottomButtons.map((bottomButton, bottomButtonIndex) => (
                  <ButtonComponent
                    {...bottomButton}
                    key={`popup-component-button-${bottomButtonIndex}`}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default PopupComponent;
