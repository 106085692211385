export interface NotificationComponentProps {
  notificationType: NotificationTypes;
  onDestroy: Function;
  headerText?: string;
  notificationText?: string;
  duration: number;
  iconColor?: string;
}

export enum NotificationTypes {
  INFO = "INFO",
  SUCCESS = "SUCCESS",
  WARNING = "WARNING",
  ERROR = "ERROR",
}

export enum TypeColors {
  INFO = "#aad8e0",
  SUCCESS = "#96caa6",
  WARNING = "#ead76b",
  ERROR = "#e88c8c",
}
