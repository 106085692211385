import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { ReactComponent as CloseIcon } from "../assets/close.svg";
import { ReactComponent as SuccessIcon } from "../assets/check.svg";
import { ReactComponent as WarningIcon } from "../assets/warning.svg";
import { ReactComponent as InfoIcon } from "../assets/info.svg";
import "./NotificationComponent.scss";
import {
  NotificationComponentProps,
  TypeColors,
} from "./NotificationComponent.types";

const NotificationComponent: React.FC<NotificationComponentProps> = ({
  notificationType,
  onDestroy,
  headerText,
  notificationText,
  duration = notificationType === "ERROR" ? 100000 : 2000,
  iconColor = "white",
}) => {
  const IconIdentifier = {
    INFO: <InfoIcon style={{ color: iconColor }} />,
    SUCCESS: <SuccessIcon style={{ color: iconColor }} />,
    WARNING: <WarningIcon style={{ color: iconColor }} />,
    ERROR: <CloseIcon style={{ color: iconColor }} />,
  };

  const currentNumberOfNotifications: number =
    document.querySelectorAll(".notification-popup-component-wrapper").length +
    1;

  /**
   * useEffect to destroying an notification after a given duration
   */
  useEffect(() => {
    const destroyTimer = setTimeout(() => onDestroy(), duration);
    return () => clearTimeout(destroyTimer);
  }, []);

  return (
    <div
      data-testid={"NotificationComponent"}
      key={"popup-component-wrapper" + currentNumberOfNotifications}
      className={"notification-popup-component-wrapper"}
      onClick={() => onDestroy()}
      style={{ top: `${currentNumberOfNotifications * 60 + 45}px` }}
    >
      <div
        className="popup-component-child-wrapper"
        onClick={(event) => {
          event.stopPropagation();
        }}
        style={{ backgroundColor: TypeColors[notificationType] }}
      >
        <div className="popup-component-children">
          <div className="popup-component-children--icon-status">
            {IconIdentifier[notificationType]}
          </div>
          <div
            className="popup-component-children--icon-close"
            onClick={() => onDestroy()}
          >
            <CloseIcon />
          </div>
          <div className="popup-component-children--time">
            {new Date().toLocaleTimeString("de", {
              hour: "2-digit",
              minute: "2-digit",
            })}
          </div>
          <div
            data-testid={"inner-notification"}
            className="popup-component-children--text"
          >
            <div className="popup-component-children--text-header">
              {headerText}
            </div>
            <div className="popup-component-children--text-content">
              {notificationText}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Method to generate a notification and append it to the body
 * @param notificationType type of the notification "INFO", "SUCCESS", "WARNING", ERROR
 * @param headerText text that gives information about notification topic
 * @param notificationText text that gives further infornation about the notification
 * @param iconColor color of icons in notification
 * @param duration duration of showing the notification in ms
 */
export const generateNotification = (
  notificationType,
  headerText,
  notificationText,
  iconColor = "white",
  duration?: number
): void => {
  let divWrapper: HTMLDivElement = document.createElement("div");
  let idName: string =
    "notification-" +
    (Math.random() * Number(Date.now().toFixed(0))).toFixed(0);
  divWrapper.id = idName;
  const createdNotification = React.createElement(
    NotificationComponent,
    {
      headerText: headerText,
      notificationText: notificationText,
      iconColor: iconColor,
      notificationType: notificationType,
      duration: duration,
      onDestroy: () => document.getElementById(idName)?.remove(),
    },
    null
  );
  ReactDOM.render(createdNotification, document.body.appendChild(divWrapper));
};

export default NotificationComponent;
