import { FooterArea, LayoutComponentProps } from "./LayoutComponent.types";
import RanLogo from "../assets/ran_logo.png";
import React from "react";
import "./LayoutComponent.scss";
import FooterImage from "../assets/footer_image.png";
import CookieBannerComponent from "../CookieBannerComponent/CookieBannerComponent";

const LayoutComponent: React.FC<LayoutComponentProps> = ({
  logo,
  children,
  gradient,
  footer,
  title = "RAN Gruppe",
  isIframe = false,
  cookieConfig,
}) => {
  /**
   * Helper to generate a footer layout
   * @param footer needed datat for footer (optional)
   * @returns generated footer
   */
  const createFooter = (footer?: FooterArea[]): JSX.Element => {
    if (!footer) return <></>;
    const footerElements: JSX.Element[] = [];
    footer.forEach((column) => {
      const footerColumn: JSX.Element[] = [];
      column.content.forEach((entry) => {
        footerColumn.push(
          entry.link ? (
            <a href={entry.link} key={entry.title}>
              <p>{entry.title}</p>
            </a>
          ) : (
            <p key={entry.title}>{entry.title}</p>
          )
        );
      });
      footerElements.push(
        <div
          key={`${column.title}-${column.content.length}`}
          className="footer-container"
        >
          <p>{column.title}</p>
          {footerColumn}
        </div>
      );
    });
    return (
      <div className="layout-component-footer">
        <div
          className="layout-component-footer--image-wrapper"
          style={{ backgroundImage: `url(${FooterImage})` }}
        />
        <div className="layout-component-footer--content">{footerElements}</div>
      </div>
    );
  };

  return (
    <div data-testid="LayoutComponent" className="layout-component-wrapper">
      {!isIframe && (
        <div className="layout-component-header">
          <a href="/">
            <div className="header-logo-wrapper">
              <img src={!!logo ? logo : RanLogo} id="header-logo" />
              <p>{title}</p>
            </div>
          </a>
        </div>
      )}
      <div className="layout-component-main">
        <div
          data-testid="inner-layout"
          className={[
            "layout-component-content",
            isIframe ? " iFrame" : "undefinded",
          ].join(" ")}
          style={{
            backgroundImage:
              gradient &&
              "linear-gradient(to bottom, #ddf9df 0%, #bbe3e0 60%, #97cbe2 100%)",
          }}
        >
          {children}
        </div>
        {!isIframe && createFooter(footer)}
      </div>
      {cookieConfig?.active && <CookieBannerComponent config={cookieConfig} />}
    </div>
  );
};

export default LayoutComponent;
