import React, { useEffect, useState } from "react";
import { DropdownComponentProps, Option } from "./DropdownComponent.types";
import { ReactComponent as ClearIcon } from "../assets/close.svg";
import { ReactComponent as UpArrow } from "../assets/up_arrow.svg";
import { ReactComponent as DownArrow } from "../assets/down_arrow.svg";

import "./DropdownComponent.scss";

const DropdownComponent: React.FC<DropdownComponentProps> = ({
  disabled,
  required,
  options,
  selectedOption = "",
  onChange = () => {},
  searchable,
  allowVariableValue,
  allowMultiSelect,
  selectedOptions = [],
  onChangeMultiple = () => {},
  upperLabel,
  placeholder,
  border,
  classNameOptions,
  classNameOption,
  classNameInput,
  classNameIcons,
  classNameLabel,
  noDataLabel,
}) => {
  const [position, setPosition] = useState<DOMRect>();
  const [showOptions, toggleShowOptions] = useState<boolean>(false);
  const [variableValue, setVariableValue] = useState<string>("");
  const [mouseOnOptions, toggleMouseOnOptions] = useState<boolean>(false);
  const [filteredOptions, setFilteredOptions] = useState<Option[]>([]);
  const [optionsForDropdown, setOptionsForDropdown] = useState<Option[]>([]);
  const [showValues, toggleShowValues] = useState<boolean>(true);
  const [dropdownValue, setDropdownValue] = useState<string>("");
  const keyCodeForEnter: number = 13;
  //unique id for Dropdown
  const randomId: string = (
    Math.random() * Number(Date.now().toFixed(0))
  ).toFixed(0);
  const randomIdOptions: string = (
    Math.random() * Number(Date.now().toFixed(0))
  ).toFixed(0);

  if (global.window) {
    //event handler for scrolling of window
    window.onwheel = (evt) => {
      if (!evt.target?.id?.includes(randomIdOptions)) abortSelection();
    };
    //event handler for scrolling of window mobile
    window.ontouchmove = (evt) => {
      if (!evt.target?.id?.includes(randomIdOptions)) abortSelection();
    };
  }

  //add start to label and placeholder when required
  const labelText = `${upperLabel ? upperLabel : ""}${
    required ? `${upperLabel ? " " : ""}*` : ""
  }`;
  const placeholderText = `${placeholder ? placeholder : ""}${
    required ? `${placeholder ? " " : ""}*` : ""
  }`;

  /**
   * closes dropdown
   */
  const abortSelection = (): void => {
    toggleMouseOnOptions(false);
    toggleShowOptions(false);
    if (!allowVariableValue) setVariableValue("");
    if (allowMultiSelect) toggleShowValues(true);
  };

  /**
   * opens dropdown, hides value tags (for search or variable value), sets focus on
   * underlying input and opens dropdown for selection
   */
  const openDropdownAndFocusInput = (): void => {
    if (disabled || !document) return;
    toggleShowValues(false);
    if (allowVariableValue || searchable)
      document.getElementById(`dropdown-value-input-${randomId}`).focus();
    const boundingClientRect: DOMRect = document
      .getElementById(randomId)
      .getBoundingClientRect();
    setPosition(boundingClientRect);
    toggleShowOptions(true);
  };

  /**
   * Helper method get the correct label for given option
   * @param value of option
   * @returns label for value or value itself if no option was found
   */
  const getLabelForValue = (value: string): string => {
    const correctIndex: number = options.findIndex(
      (option) => option.value === value
    );
    if (correctIndex === -1) return value;
    return options[correctIndex].label;
  };

  /**
   * Helper to add a values to selectedValues
   * @param value
   */
  const addValue = (value: string): void => {
    let updatedSelectedValues: string[] = selectedOptions;
    updatedSelectedValues.push(value);
    onChangeMultiple([...updatedSelectedValues]);
  };

  //inittially sets selected Value as variable value, when variable text is allowed
  useEffect(() => {
    if (allowVariableValue && selectedOption) setVariableValue(selectedOption);
  }, []);

  //we use variableValue for search too, if searchable is selected, else we just set all options
  useEffect(() => {
    if (variableValue && searchable) {
      const filteredOptions: Option[] = optionsForDropdown.filter((option) =>
        option.value.toUpperCase().includes(variableValue.toUpperCase())
      );
      setFilteredOptions([...filteredOptions]);
    } else setFilteredOptions(optionsForDropdown);
  }, [variableValue, optionsForDropdown]);

  //filters options by already selected values so a value can't occur twice in multiselect
  useEffect(() => {
    const availableOptions: Option[] = options.filter((option) =>
      allowMultiSelect
        ? !selectedOptions.includes(option.value)
        : option.value !== selectedOption
    );
    setOptionsForDropdown([...availableOptions]);
  }, [selectedOption, selectedOptions.length]);

  return (
    <div
      data-testid="DropdownComponent"
      className={`dropdown-component${!border ? "-noBorder" : ""}`}
      id={randomId}
    >
      {labelText && (
        <div
          data-testid="inner-dropdown-label"
          className={[
            "dropdown-component--label",
            classNameLabel ? classNameLabel : undefined,
          ].join(" ")}
        >
          <p>{labelText}</p>
        </div>
      )}
      <div
        data-testid="inner-dropdown"
        className={[
          "dropdown-component--value-wrapper",
          disabled ? "disabled" : undefined,
        ].join(" ")}
        onClick={(event) => {
          event.stopPropagation();
          openDropdownAndFocusInput();
        }}
      >
        {/* once display input is disabled, required & onClick events do not work, so we create an invisible trigger element that is not disabled */}
        <input
          data-testid="inner-dropdown-trigger-input"
          id={`dropdown-trigger-input-${randomId}`}
          disabled={disabled}
          className={[
            "dropdown-component--required-trigger",
            searchable ||
            allowVariableValue ||
            (allowMultiSelect && showValues && selectedOptions.length > 0)
              ? "no-pointer-events"
              : undefined,
            showOptions && "hardBottom",
          ].join(" ")}
          required={required}
          value={
            allowMultiSelect
              ? selectedOptions.length === 0
                ? ""
                : selectedOptions.length
              : options.find((option) => option.value === selectedOption)
                  ?.label ||
                (searchable || allowVariableValue ? variableValue : "")
          }
          // onChange={(event) => {
          //   setDropdownValue(event.target.value);
          // }}
          onClick={() => {
            if (disabled) return;
            const boundingClientRect: DOMRect = document
              .getElementById(randomId)
              .getBoundingClientRect();
            setPosition(boundingClientRect);
            toggleShowOptions(!showOptions);
          }}
          onBlur={() => {
            toggleShowOptions(false);
            if (!allowVariableValue) setVariableValue("");
          }}
        />

        {allowMultiSelect &&
          selectedOptions.length > 0 &&
          (showValues || (!allowVariableValue && !searchable)) && (
            <div
              className={[
                "dropdown-component--values",
                disabled ? "disabled" : undefined,
              ].join(" ")}
            >
              {selectedOptions.map((value, index) => (
                <div
                  key={`dropdown-multiple-values-${index}`}
                  className="dropdown-component--values--tag"
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                >
                  <div>{getLabelForValue(value)}</div>
                  <div data-testid="inner-icon-id">
                    <ClearIcon
                      className={classNameIcons ? classNameIcons : undefined}
                      onClick={(event) => {
                        event.stopPropagation();
                        abortSelection();
                        onChangeMultiple(
                          selectedOptions.filter(
                            (currentValue) => currentValue !== value
                          )
                        );
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          )}
        <input
          data-testid="inner-dropdown-input-two"
          readOnly={!searchable && !allowVariableValue}
          id={`dropdown-value-input-${randomId}`}
          className={[
            "dropdown-component--value",
            classNameInput ? classNameInput : undefined,
            disabled || searchable || allowVariableValue
              ? undefined
              : "no-lower-opacity",
            showOptions && "hardBottom",
          ].join(" ")}
          placeholder={
            selectedOptions.length === 0 ? placeholderText : undefined
          }
          disabled={disabled}
          value={
            options.find((option) => option.value === selectedOption)?.label ||
            (searchable || allowVariableValue ? variableValue : "")
          }
          onChange={(event) => {
            setVariableValue(event.target.value);
            if (allowVariableValue && !allowMultiSelect)
              onChange(event.target.value);
          }}
          onClick={(event) => {
            event.stopPropagation();
            if (disabled) return;
            const boundingClientRect: DOMRect = document
              .getElementById(randomId)
              .getBoundingClientRect();
            setPosition(boundingClientRect);
            toggleShowOptions(!showOptions);
          }}
          onBlur={() => {
            if (allowMultiSelect) toggleShowValues(true);
            toggleShowOptions(false);
            if (!allowVariableValue && !mouseOnOptions) setVariableValue("");
          }}
          onKeyUp={(event) => {
            if (
              event.keyCode === keyCodeForEnter &&
              allowVariableValue &&
              allowMultiSelect
            ) {
              addValue(variableValue);
              setVariableValue("");
              abortSelection();
            }
          }}
        />

        {selectedOption && !disabled && !allowMultiSelect && (
          <div data-testid="inner-dropdown-icon">
            <ClearIcon
              className={[
                "dropdown-component--clear-icon",
                classNameIcons ? classNameIcons : undefined,
              ].join(" ")}
              onClick={(event) => {
                event.stopPropagation();
                onChange("");
                setVariableValue("");
              }}
            />
          </div>
        )}

        {!disabled &&
          optionsForDropdown.length > 0 &&
          (showOptions ? (
            <UpArrow
              className={[
                "dropdown-component--icon",
                classNameIcons ? classNameIcons : undefined,
              ].join(" ")}
              onClick={(event) => {
                event.stopPropagation();
                toggleShowOptions(false);
              }}
            />
          ) : (
            <DownArrow
              className={[
                "dropdown-component--icon",
                classNameIcons ? classNameIcons : undefined,
              ].join(" ")}
              onClick={(event) => {
                event.stopPropagation();
                openDropdownAndFocusInput();
              }}
            />
          ))}
      </div>
      <div
        data-testid="inner-dropdown-option"
        key={`dropdown-component-options-${filteredOptions.length}`}
        id={`options-${randomIdOptions}`}
        className={[
          "dropdown-component--options",
          classNameOptions ? classNameOptions : undefined,
        ].join(" ")}
        hidden={!showOptions && !mouseOnOptions}
        style={
          position && {
            top: position?.top + position?.height,
            left: position?.left,
            //remove 50px because of 25px border radius on each side
            width: position?.width - 10,
            position: "fixed",
          }
        }
        onMouseEnter={() => toggleMouseOnOptions(true)}
        onMouseLeave={() => toggleMouseOnOptions(false)}
      >
        {filteredOptions.length > 0 ? (
          filteredOptions.map((option, index) => (
            <div
              key={`dropdown-component-option-${index}-${option.value}-${filteredOptions.length}`}
              id={`option-${randomIdOptions}`}
              className={[
                "dropdown-component--options--option",
                classNameOption ? classNameOption : undefined,
                option.disabled ? "disabled" : undefined,
              ].join(" ")}
              onClick={() => {
                if (option.disabled) return;
                if (allowMultiSelect) addValue(option.value);
                else onChange(option.value);
                abortSelection();
              }}
            >
              {option.label}
            </div>
          ))
        ) : (
          <div
            data-testid="inner-dropdown-option-id"
            id={`option-${randomIdOptions}`}
            key={`dropdown-component-option-no-data`}
            className={[
              "dropdown-component--options--option disabled",
              classNameOption ? classNameOption : undefined,
            ].join(" ")}
          >
            {noDataLabel || "No Data"}
          </div>
        )}
      </div>
    </div>
  );
};

export default DropdownComponent;
