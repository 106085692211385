import { CheckboxComponentProps } from "./CheckboxComponent.types";
import React from "react";
import "./CheckboxComponent.scss";

const CheckboxComponent: React.FC<CheckboxComponentProps> = ({
  value,
  disabled,
  checked,
  onCheck,
  required,
  type,
}) => {
  return (
    <div
      data-testid="CheckboxComponent"
      className={
        type === "cookie" ? "checkbox-component--cookies" : "checkbox-component"
      }
    >
      <input
        data-testid="inner-input"
        required={required}
        disabled={disabled}
        checked={checked}
        type="checkbox"
        className="checkbox-component-checkbox"
        onChange={(event) => onCheck(event.target.checked)}
      />

      <label
        className={
          disabled
            ? "checkbox-component-label-disabled"
            : "checkbox-component-label"
        }
      >
        {value}
      </label>
    </div>
  );
};

export default CheckboxComponent;
