// Generated with util/create-component.js
import React from "react";
import { FileInputComponentProps } from "./FileInputComponent.types";
import { ReactComponent as UploadIcon } from "../assets/upload.svg";
import { ReactComponent as Trashcon } from "../assets/trash.svg";
import "./FileInputComponent.scss";

const FileInputComponent: React.FC<FileInputComponentProps> = ({
  file,
  onFileChange = () => {},
  accept,
  upperLabel,
  required,
  emptyLabel,
  capture,
  disabled,
}) => {
  const uniqueId: string =
    "file-input-component-" + Math.floor(Math.random() * 99999);
  const labelText: string = upperLabel
    ? `${upperLabel}${required ? " *" : ""}`
    : undefined;

  return (
    <div data-testid="FileInputComponent" className="file-input-component">
      {upperLabel && (
        <div className="input-label">
          <p>{labelText}</p>
        </div>
      )}
      <label htmlFor={uniqueId} className={disabled ? "disabled" : undefined}>
        <div>
          <UploadIcon className="file-input-component--icon" />
          <span className={!file ? "file-input-component--empty" : undefined}>
            {file?.name ?? emptyLabel}
          </span>
          {file && (
            <Trashcon
              className="file-input-component--icon"
              onClick={(evt: Event) => {
                evt.stopPropagation();
                evt.preventDefault();
                if (disabled) return;
                onFileChange();
              }}
            />
          )}
        </div>
      </label>
      <input
        disabled={disabled}
        id={uniqueId}
        value={!file ? "" : undefined}
        type="file"
        onChange={(evt) => onFileChange(evt.target.files?.[0])}
        accept={accept}
        capture={capture}
      />
    </div>
  );
};

export default FileInputComponent;
