import { ButtonComponentProps } from "./ButtonComponent.types";
import React from "react";
import "./ButtonComponent.scss";

const ButtonComponent: React.FC<ButtonComponentProps> = ({
  className,
  disabled,
  type = "button",
  value,
  onClick,
  form,
  color,
  textColor,
  gradient,
  isLoading = false,
}) => (
  <div
    data-testid="ButtonComponent"
    className={className ? `button-component ${className}` : "button-component"}
  >
    <button
      data-testid="inner-button"
      className={gradient ? "gradient" : ""}
      disabled={isLoading || disabled}
      type={type}
      onClick={onClick}
      form={form}
      style={{ backgroundColor: color, borderColor: color, color: textColor }}
    >
      {value}
    </button>
  </div>
);

export default ButtonComponent;
