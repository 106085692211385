// Generated with util/create-component.js
import React from "react";

import { AnchorComponentProps } from "./AnchorComponent.types";

import "./AnchorComponent.scss";

const AnchorComponent: React.FC<AnchorComponentProps> = ({
  target,
  blank,
  className,
  gradient,
  textColor,
  color,
  value,
  children,
  onClick,
}) => {
  /**
   * Helper to determine if an anchor is pressed or not
   * @returns if pressed should be executed
   */
  const handleOnClick = (): boolean => {
    if (!!onClick) onClick();
    return true;
  };

  return (
    <div
      data-testid="AnchorComponent"
      className={
        className ? `anchor-component ${className}` : "anchor-component"
      }
    >
      <a
        data-testid="inner-anchor"
        className={gradient ? "gradient" : ""}
        style={{ backgroundColor: color, borderColor: color, color: textColor }}
        href={target}
        target={blank ? "_blank" : "_self"}
        rel="noopener nofollow"
        onClick={handleOnClick}
      >
        {value}
        {children}
      </a>
    </div>
  );
};

export default AnchorComponent;
